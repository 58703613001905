.spin {
  height: 100%;
  width: 100%;

  :global{
    .ant-spin-container {
      height: 100%;
    }
  }
}

.home {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .footMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1vw 0;

    .footTitle {
      font-size: 0.7vw;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0.2vw 0;
    }

    .joinIcons {
      display: flex;
    }

    .joinImg {
      width: 1vw;
      margin: 0 0.2vw;
      cursor: pointer;
    }
  }

  .minMenu {
    height: calc(100vh - 95px);
    width: 92vw;
    overflow: auto;
    position: absolute;
    z-index: 99;
    background: #ffffff;
    top: 95px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 4vw;

    .menuList {
      flex: 1;
    }

    .footMenu {
      justify-content: flex-start;
      align-items: flex-start;

      .joinImg {
        width: 4vw;
      }
    }
  }

  .minTop {
    height: 55px;
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4vw;
    font-size: 2vw;
    color: #C8080D;
  }

  .head {
    padding: 0 2vw;
    height: 3vw;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;

    .headLeft {
      display: flex;

      .title {
        margin-right: 1.5vw;
        font-weight: 600;
        color: #505050;
        cursor: pointer;
      }

      .tag {
        padding: 0.2vw 0.7vw;
        border-radius: 2.08333vw;
        background: #C8080D;
        color: #FFFFFF;

        .tagText {
          position: relative;
          top: -0.1vw;
        }
      }
    }

    .headRight {
      display: flex;
      font-size: 0.8vw;

      .linkOne {
        display: flex;
        align-items: center;
        margin-left: 2vw;
        font-weight: 600;
        color: #4A4A4A;
        cursor: pointer;

        .icon {
          margin-right: 0.5vw;
        }
      }
    }
  }

  .body {
    flex: 1;
    display: flex;
    overflow: hidden;

    .bodyLeft {
      width: 340px;
      min-height: calc(100vh - 3vw);
      background: #C8080D;
      display: flex;

      .leftContainer {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      .rightContainer {
        width: 170px;
        height: 100%;
        background: #C8080D;
        border-left: 1px solid #FFFFFF;
      }
      //.menuList::-webkit-scrollbar {
      //  /*滚动条整体样式*/
      //  width: 3px;
      //  /*高宽分别对应横竖滚动条的尺寸*/
      //  height: 3px;
      //}

      .menuList::-webkit-scrollbar-thumb {
        /*滚动条里面深色条*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(236, 236, 236, 0.1);
        background: red;
      }
      .menuList::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(236, 236, 236, 0.1);
        border-radius: 10px;
        background: #C8080D;
      }

      .menuList {
        flex: 1;
        overflow: auto;
        width: 100%;
      }

      .log {
        width: 5vw;
        margin: 1.5vw 0;
      }

      :global {
        //.ant-menu {
        //  background: #C8080D;
        //  width: 100%;
        //}
        //.ant-menu-item-selected {
        //  background: rgba(0, 0, 0, 0.06);
        //}
        .ant-menu-title-content {
          font-weight: 600;
        }
        //.ant-menu-submenu-arrow::after {
        //  color: #FFFFFF;
        //}
        //.ant-menu-submenu-arrow::before {
        //  color: #FFFFFF;
        //}
      }
    }

    .bodyRight {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .contentBody {
        flex: 1;
      }

      .footInfo {
        background: #343434;
        padding: 3.8vw 0 2.6vw 5.4vw;
        font-size: 0.8vw;
        white-space: nowrap;

        .minInfo {
          font-size: 1.5vw;
        }

        .info {
          display: flex;
          flex-wrap: wrap;

          .itemContainer {
            margin: 0 5vw 2.6vw 0;

            .title {
              font-weight: 600;
              margin-bottom: 1vw;
              color: #FFFFFF;
            }

            .extraLabel {
              color: #B3B2B2;
              margin-bottom: 1vw;
            }

            .extraList {
              color: #B3B2B2;
              margin-bottom: 1vw;
            }
          }
        }

        .minNetCode {
          font-size: 1.2vw!important;
        }

        .netCode {
          font-size: 0.8vw;
          color: #B3B2B2;

          .netText {
            display: block;
            color: #B3B2B2;
            margin-bottom: 0.6vw;
          }
        }
      }
    }
  }
}

.popContent {
  width: 7vw;
  height: 7vw;
  overflow: hidden;

  .popImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.popContentHeight {
  width: 7vw;
  height: 8vw;

  .popImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

:global {
  .ant-popover-inner {
    padding: 0!important;
  }
}