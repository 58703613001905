body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100vh;
}

.App {
  height: 100%;
}

.main {
  height: 100%
}

p {
  margin: 0;
}

*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面深色条*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(236, 236, 236, 0.1);
  background: rgb(200, 8, 13);
}*::-webkit-scrollbar-track {
   /*滚动条里面轨道*/
   box-shadow: inset 0 0 5px rgba(236, 236, 236, 0.1);
   border-radius: 10px;
   background: #FFFFFF;
 }
